<template>
    <div v-if="loading == false">
        <!-- If the columns for the ChirpList are changed at all, it will break the css for the modal address book.
             See Nate if that happens.  -->
        <ChirpList
            ref="list"
            :data_source="rows"
            @rowSelected="handleRowSelected"
            :totalCount="totalCount"
            :hideSelectColumn="hideSelectColumn"
            @applyCriteria="modifyCriteria"
            :loadingColumns="loadingTable"
            :storeKey="storeKey"
            :useOverride="useOverride"
            @cancelOverride="handleCancelOverride"
            @addSelectedRecords="handleAddSelectedRecords"
            :hideFilter="true"
            :hideAction="true"
            :hideColumn="true"
            :hideReset="true"
            :createButton="createButton"
            @addNewRecord="openNewContactModal"
        />
        <NewContactModal ref="NewContactModal" @addNewContact="addNewContact" />
    </div>
    <div v-else>Loading...</div>
</template>

<script>
    import { tryGetFilter } from '@/util/tryGetFilter';
    import { contacts } from '@/util/apiRequests';

    const api_root = 'email/address-book';

    import ChirpList from '@/components/general/list/ChirpList';

    import NewContactModal from '../general/modals/NewContactModal';

    export default {
        name: 'EmailAddressList',
        components: { ChirpList, NewContactModal },
        props: {
            overrideFilter: {
                type: Object,
                default: null
            },
            hideSelectColumn: {
                type: Boolean,
                default: true
            },
            createButton: {
                type: Boolean,
                default: false
            },
            onlyOneClient: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                rows: [],
                totalCount: 0,
                targetPage: 'EmailEditor',
                loading: true,
                loadingTable: false,
                useOverride: false,
                columnMeta: [],
                criteriaProps: {}
            };
        },
        computed: {
            criteria() {
                return this.useOverride && this.overrideFilter ? this.overrideCriteria : this.savedCriteria;
            },
            savedCriteria() {
                return tryGetFilter(this.$store, this.storeKey);
            },
            overrideCriteria() {
                return this.$store.getters['filters/overrideCriteria'](this.storeKey);
            },
            storeKey() {
                return this.pageMeta?.page || 'address-book';
            }
        },
        methods: {
            clearSelection() {
                this.$refs.list.clearSelection();
            },
            handleAddSelectedRecords(e) {
                if (!this.hideSelectColumn) {
                    this.$emit('addSelectedRecords', e);
                }
            },
            handleRowSelected(args) {
                if (this.hideSelectColumn) {
                    let params = {
                        address: args.email,
                        first_name: args?.first_name,
                        last_name: args?.last_name
                    };
                    if (args.type == 'client') {
                        params.client_id = args.id;
                    }
                    if (args.type == 'user') {
                        params.to_user_id = args.id;
                    }
                    this.$router.push({ name: this.targetPage, params: params });
                }
            },
            async load_data() {
                const body = {
                    criteria: this.criteria
                };
                let copy = JSON.parse(JSON.stringify(body));

                if(this.onlyOneClient) {

                     copy.criteria.filter = {
                         type: ['user', 'other']
                     }
                   // body.client_id = this.modal_client_id;
                }
                const res = await this.$api.post(`/${api_root}`, copy);
                this.rows = res.data.rows;

                this.totalCount = res.data.total_count;
                this.$nextTick(() => {
                    this.loading = false;
                });
            },
            async init() {
                if (this.overrideFilter) {
                    this.useOverride = true;
                    this.$store.commit('filters/applyOverride', {
                        stateKey: this.storeKey,
                        criteria: this.overrideFilter
                    });
                }
                await this.load_data();
            },
            async modifyCriteria(criteria) {
                if (this.targetPage) {
                    this.$store.commit('filters/update', {
                        criteriaPage: this.targetPage,
                        payload: criteria
                    });
                }
                this.criteriaProps = criteria;
                await this.load_data();
            },
            handleCancelOverride() {
                this.useOverride = false;
            },
            openNewContactModal() {
                // Open modal
                this.$refs.NewContactModal.openModal();
            },
            async addNewContact(contact) {
                await this.$api.put(contacts.saveContact('new'), {
                    first_name: contact.firstName,
                    last_name: contact.lastName,
                    email: contact.email,
                    contact_type: contact.type
                });
                await this.load_data();
            }
        },
        watch: {
            criteria: {
                deep: true,
                async handler() {
                    await this.load_data();
                }
            }
        },
        async created() {
            await this.init();
        }
    };
</script>
