<template>
    <div>
        <vIfWorkableModal v-if="open" @close="closeModal">
            <ValidationObserver class="" ref="form">
                <form class="no-style" @submit.prevent="submit">
                    <div>
                        <h2>Add A New Contact</h2>
                        <Input
                            class=" bottom-15 block"
                            label="Email"
                            type="email"
                            name="Email"
                            id="emailInput"
                            rules="email"
                            required
                            v-model="contact.email"
                        />
                        <div class="flex colx2 space-between bottom-15">
                            <Input
                                class=" bottom-15 block"
                                label="First Name"
                                type="text"
                                name="First Name"
                                id="firstNameInput"
                                required
                                v-model="contact.firstName"
                            />
                            <Input
                                class=" bottom-15 block"
                                label="Last Name"
                                type="text"
                                name="Last Name"
                                id="lastNameInput"
                                v-model="contact.lastName"
                            />
                        </div>
                        <div class="align-right">
                            <input type="submit" value="Save" />
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </vIfWorkableModal>
    </div>
</template>

<script>
import vIfWorkableModal from "./vIfWorkableModal"
import Input from '@/components/general/validatedInputs/Input.vue';
import { ValidationObserver } from 'vee-validate';

export default {
    name: "EmailAddressBookModal",
    components: { vIfWorkableModal, Input, ValidationObserver },
    data() {
        return {
            open: false,
            contact: {
                firstName: '',
                lastName: '',
                email: '',
                type: 'other',
            }
        }
    },
    methods: {
        openModal() {
            this.open = true;
        },
        closeModal() {
            this.open = false;
        },
        async submit() {
            const form = this.$refs.form;
            const isValid = await form.validate();

            if (!isValid) {
                //this.$toasted.error('Invalid fields');
                return;
            } else {
                this.addNewContact()
            }
        },
        addNewContact() {
            this.open = false;
            this.$emit("addNewContact", {
                ...this.contact
            });
        },
    },
}
</script>