<template>
    <EmailAddressList
        :createButton="true"
    />
</template>

<script>
import EmailAddressList from "../../components/email/EmailAddressList";


export default {
    name: 'EmailAddressBook',
    components: { EmailAddressList },
};
</script>
